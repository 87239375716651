import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'

import Layout, {media} from './layout'
import Seo from './seo'
import Chart from './chart'
import coincheck from '../data/exchanges/coincheck.json'
import coinsJson from '../data/data_handler'
import tempCoinsJson from '../data/temp_coin_handler'
import charts from '../data/chart_handler'


const Coincheck = () => {

    let coins = []

    coincheck['coins'].map(coin => {
        if (coinsJson[coin]) {
            coins.push(coinsJson[coin])
        } else if (tempCoinsJson[coin]) {
            coins.push(tempCoinsJson[coin])
        }
    })
    console.log('coins: ', coins)

    return (
        <Layout>
            <Seo title='Coincheck'/>
            <HeaderBackground>
                <HeaderWrapper>
                    <Logo>
                        <img src={`/images/exchange_icons/${coincheck['icon']}`} alt={coincheck['name']}/>
                    </Logo>
                    <BusinessName>
                        <h2>{coincheck['jpname']}</h2>
                        <div>Coincheck（コインチェック）は、コインチェック株式会社が運営する暗号通貨取引所。
                            マネックスグループ株式会社の完全子会社の仮想通貨交換業者である。
                        </div>
                    </BusinessName>
                </HeaderWrapper>
            </HeaderBackground>

            <SectionHeader>
                <SectionHeaderIcon url={`/images/exchange_icons/${coincheck['icon']}`}/>
                <h2>{coincheck['jpname']}の取り扱い通貨一覧</h2>
            </SectionHeader>
            <ChartWrapper>
                {coins.map(coin =>
                    <Link to={coinsJson[coin['symbol']] ? `/coins/${coin['symbol']}` : `/404`}>
                        <Chart coin={coin} chart={charts[coin['symbol']] ? charts[coin['symbol']] : charts['btc']}/>
                    </Link>
                )}
            </ChartWrapper>
        </Layout>
    )
}

export default Coincheck


const Frame = styled.section`
    border-radius: 0.625vw;
    padding: calc(( 65 / 1280 ) *100vw ) calc(( 40 / 1280 ) *100vw );
    // width: calc(( 1000 / 1280 ) *100vw );
    // max-width: 1000px;
    margin: calc(( 75 / 1280 ) *100vw ) auto 0 auto;
    background-color: #fff;
    
    ${media.small`
        padding: calc((100vw / 750 ) * 100 ) calc((100vw / 750 ) * 20 ) calc((100vw / 750 ) * 60 ) calc((100vw / 750 ) * 20 );
        margin: calc((100vw / 750 ) * 80 ) calc((100vw / 750 ) * 40 ) calc((100vw / 750 ) * 40 );
        border-radius: 10px;
    `}   
`


const HeaderBackground = styled.header`
    margin: 5px 0;
    background-color: #fafafa;
`

const HeaderWrapper = styled.div`
    ${media.large`
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        justify-content: center;
        
        width: calc(( 1000 / 1280 ) *100vw );
        max-width: 1000px;
        height: calc(( 375 / 1280 ) *100vw );
        max-height: 375px;
        
        position: relative;
        margin: 0 auto;
        padding: 0;   
    `}

    ${media.lessThanLarge`
        display: grid;
        justify-content: center;
    `}
`

const Logo = styled.figure`
    width: calc(( 300 / 1280 ) *100vw );
    height: auto;
    max-width: 300px;
    text-align: center;
    margin: 0;
    
    ${media.lessThanLarge`
        width: calc((100vw / 750 ) * 300 );
        margin: calc((100vw / 750 ) * 40 ) auto 10px;
    `} 
`

const BusinessName = styled.div`
    display: grid;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 8px 8px 0 #d9d9d9;
    text-align: left;
    
    > h2 {
        padding: 0 20px;
    }
    
    > div {
        padding: 0 20px;
    }

    ${media.large`
        width: calc(( 500 / 1280 ) *100vw );
        padding: calc(( 30 / 1280 ) *100vw ) calc(( 30 / 1280 ) *100vw ) calc(( 30 / 1280 ) *100vw ) calc(( 45 / 1280 ) *100vw );
        max-width: 500px;
        margin: 0 auto 0 0;
    `}
    
    ${media.lessThanLarge`
        padding: calc((100vw / 750 ) * 30 ) 0;
        margin: 0 calc((100vw / 750 ) * 40 );
    `}
`

const SectionHeader = styled.div`
    position: relative;
    border: solid 0px #ccc;
    text-align: center;
    padding: 150px 0 20px 0;
    margin-top: 20px;
    
    h2 {
        font-weight: 700;
        font-size: 2.4rem;
        margin: 0;
        padding: 0 20px;
        background: transparent;
    }
`

const SectionHeaderIcon = styled.div`
    background: url(${(props) => props.url});
    background-size: contain;
    
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    width: 130px;
    height: 130px;
    
    svg {
        font-size: 5.5rem;
    }
`

const ChartWrapper = styled(Frame)`
    display: grid;
    justify-content: center;
    column-gap: 20px;
    row-gap: 20px;
    
    ${media.small`
        grid-template-columns: 160px 160px;
    `} 
    
    ${media.medium`
        grid-template-columns: 160px 160px 160px;
    `} 
    
    ${media.large`
        grid-template-columns: 160px 160px 160px 160px;
    `} 
    
    ${media.xlarge`
        grid-template-columns: 160px 160px 160px 160px 160px 160px;
    `} 
`