const mona = require('./coins/mona.json')
const lsk = require('./coins/lsk.json')
const xlm = require('./coins/xlm.json')
const qtum = require('./coins/qtum.json')
const bat = require('./coins/bat.json')
const iost = require('./coins/iost.json')
const plt = require('./coins/plt.json')
const dai = require('./coins/dai.json')
const dep = require('./coins/dep.json')
const etc = require('./coins/etc.json')
const ftt = require('./coins/ftt.json')
const gyen = require('./coins/gyen.json')
const ht = require('./coins/ht.json')
const jmy = require('./coins/jmy.json')
const mkr = require('./coins/mkr.json')
const ont = require('./coins/ont.json')
const qash = require('./coins/qash.json')
const sol = require('./coins/sol.json')
const trx = require('./coins/trx.json')
const xtz = require('./coins/xtz.json')


module.exports = {mona, lsk, xlm, qtum, bat, iost, plt, dai, dep, etc, ftt, gyen, ht, jmy, mkr, ont, qash, sol, trx, xtz}